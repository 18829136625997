import Image from '../Image';
import Link from 'next/link';
import styles from '../../../styles/modules/footer.module.scss';
import { Hidden } from '@mui/material';
import {getUtmString, combineParamsString} from '../../../utils';
import React, {useEffect} from 'react';
import { linkClicked, referNow, socialMediaIconClick } from '../../../tracking/segment/footer';
import { getPageURL } from '../../../utils/tracking';
import { useRouter } from 'next/router';
import Button from '../ui-components/input/Button';
import { isMobile } from 'react-device-detect';

const Footer = (props) => {
    const [utmParams, setUtmParams] = React.useState<any>('')
    const hide = props.pineLabs || props.blockSalesTouchPoint

    useEffect(() => {
        setUtmParams(getUtmString())
    })

    const getUrl = (url) => {
        return combineParamsString(url, utmParams)
    }

    const setLocalStorage = () => {
        // Setting this variable for GTM
        localStorage.setItem('journey_starts_from', 'footer');
    }
    const footerLinkClick = (link) =>{
        linkClicked({'link':link,...getPageURL()})
    }
    const Router = useRouter();
    return (
        <footer className={`${styles.footer} ${hide?styles.hide:''} hide_on_app_render`}>
            {<div className={`container ${styles._footer__container}`}>
                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                    <div className="row">
                        <div className={`${styles._footer_col} col-12 col-sm-12 col-md-12 col-lg-3`}>
                            <div className={`${styles._inner_row} ${styles._row_first} row`}>
                                <div className={`col-12 col-sm-12 col-md-12 col-lg-12`}>{LeftColJSX}</div>
                                <div className={`col-12 col-sm-12 col-md-12 col-lg-12`}>
                                    {ReferralJSX}
                                </div>
                            </div>
                        </div>
                        <div className={`${styles._footer_col} col-12 col-sm-12 col-md-3 col-lg-2`}>
                            <h4>Company</h4>
                            <div className={`${styles._inner_row} ${styles._row_first} row`}>
                                <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><Link href="/about-us" onClick={()=>{footerLinkClick('/about-us')}}>About Us</Link></div>
                                <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><a
                                    href={getUrl("https://onsitego.com/blog/")}
                                    onClick={()=>{footerLinkClick('https://onsitego.com/blog/')}}>Blog</a></div>
                                <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><a href="/careers" onClick={()=>{footerLinkClick('https://onsitego.com/careers/')}}>Careers</a></div>
                                <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><Link href="/media" onClick={()=>{footerLinkClick('https://onsitego.com/media/')}}>In The Media</Link></div>
                                <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><Link href="/whitepapers" onClick={()=>{footerLinkClick('https://onsitego.com/whitepapers/')}}>Whitepapers</Link></div>
                                <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><Link href="/write-to-us" onClick={()=>{footerLinkClick('/write-to-us')}}>Contact Us</Link></div>
                                <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href="/website/sitemap"
                                    onClick={()=>{footerLinkClick('/website/sitemap')}}>Sitemap</Link></div>
                            </div>

                        </div>
                        <div className={`${styles._footer_col} col-12 col-sm-12 col-md-3 col-lg-2`}>
                            <h4>Products</h4>
                            <div className={`${styles._inner_row} ${styles._row_second} row`}>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/mobile-phones-extended-warranty-plans")}
                                    onClick={()=>{footerLinkClick('/mobile-phones-extended-warranty-plans'); setLocalStorage()}}>Mobile Phones</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/?question_popup=laptops")}
                                    onClick={()=>{footerLinkClick('/?question_popup=laptops'); setLocalStorage()}}>Laptops</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/tablets-extended-warranty-plans")}
                                    onClick={()=>{footerLinkClick('/tablets-extended-warranty-plans'); setLocalStorage()}}>Tablets</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/protection-plans/digital-cameras")}
                                    onClick={()=>{footerLinkClick('/protection-plans/digital-cameras'); setLocalStorage()}}>Digital Cameras</Link></div>
                            </div>
                        </div>

                        <div className={`${styles._footer_col} ${styles._row_second} col-12 col-sm-12 col-md-3 col-lg-2`}>
                            <div className={`${styles._inner_row} ${styles._col_second} row`}>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/printers-scanners-extended-warranty-plans")}
                                    onClick={()=>{footerLinkClick('/printers-scanners-extended-warranty-plans'); setLocalStorage()}}>Printers & Scanners</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/ro-water-purifier-service-repair")}
                                    onClick={()=>{footerLinkClick('/ro-water-purifier-service-repair'); setLocalStorage()}}>Water Purifiers </Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/ac-service-repair")}
                                    onClick={()=>{footerLinkClick('/ac-service-repair'); setLocalStorage()}}>Air Conditioners</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/?question_popup=washing-machines")}
                                    onClick={()=>{footerLinkClick('/?question_popup=washing-machines'); setLocalStorage()}}>Washing Machine</Link></div>
                            </div>

                        </div>
                        <div className={`${styles._footer_col}  ${styles._row_second} col-12 col-sm-12 col-md-3 col-lg-2`}>
                            <div className={`${styles._inner_row} ${styles._col_second} row`}>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/?question_popup=refrigerators")}
                                    onClick={()=>{footerLinkClick('/?question_popup=refrigerators'); setLocalStorage()}}>Refrigerators</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/?question_popup=microwave")}
                                    onClick={()=>{footerLinkClick('/?question_popup=microwave'); setLocalStorage()}}>Microwaves</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/televisions-extended-warranty-plans")}
                                    onClick={()=>{footerLinkClick('/televisions-extended-warranty-plans'); setLocalStorage()}}>Televisions</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/fitness-trackers-extended-warranty-plans")}
                                    onClick={()=>{footerLinkClick('/fitness-trackers-extended-warranty-plans'); setLocalStorage()}}>Fitness Tracker</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/desktops-extended-warranty-plans")}
                                    onClick={()=>{footerLinkClick('/desktops-extended-warranty-plans'); setLocalStorage()}}>Desktop</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/smart-watches-extended-warranty-plans")}
                                    onClick={()=>{footerLinkClick('/smart-watches-extended-warranty-plans'); setLocalStorage()}}>SmartWatch</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/home-repair-maintenance-plans")}
                                    onClick={()=>{footerLinkClick('/home-repair-maintenance-plans'); setLocalStorage()}}>HomeCare</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/chimney-hob-repair-service")}
                                    onClick={()=>{footerLinkClick('/chimney-hob-repair-service')}}>Chimney & Hob</Link></div>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className={`${styles._footer_col} col-12 col-sm-12 col-md-12 col-lg-3`}>


                        </div>
                        <div className={`${styles._footer_col}  col-12 col-sm-12 col-md-3 col-lg-2`}>
                            <h4>Policies</h4>
                            <div className={`${styles._inner_row} row`}>
                                <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><Link href="/terms-of-use" onClick={()=>{footerLinkClick('/terms-of-use')}}>Terms Of Use</Link></div>
                                <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><Link href="/privacy-policy" onClick={()=>{footerLinkClick('/privacy-policy')}}>Privacy Policy</Link></div>
                                <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><Link href="/terms-of-service/website" onClick={()=>{footerLinkClick('/terms-of-service/website')}}>Cancellations</Link></div>
                            </div>
                        </div>
                        <div className={`${styles._footer_col} ${styles._two_col} ${styles._col_second} col-12 col-sm-12 col-md-3 col-lg-3`}>
                            <div className={`${styles._row_second} ${styles.row_mobile} row`}>
                                <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><Link href="/annual-returns" onClick={()=>{footerLinkClick('/annual-returns')}}>Annual Returns</Link></div>
                            </div>

                        </div>

                        <div className={`${styles._footer_col}  col-12 col-sm-12 col-md-3 col-lg-2`}>
                            <h4>Warranty Check</h4>
                            <div className={`${styles._row_second} row`}>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/blog/apple-warranty-check")}
                                    onClick={()=>{footerLinkClick('/blog/apple-warranty-check')}}>Apple Warranty Check</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/blog/iphone-warranty-check")}
                                    onClick={()=>{footerLinkClick('/blog/iphone-warranty-check')}}>Iphone Warranty Check</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/blog/dell-warranty-check")}
                                    onClick={()=>{footerLinkClick('/blog/dell-warranty-check')}}>Dell Warranty Check</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/blog/sony-warranty-check")}
                                    onClick={()=>{footerLinkClick('/blog/sony-warranty-check')}}>Sony Warranty Check</Link></div>

                            </div>
                        </div>
                        <div className={`${styles._footer_col} ${styles._two_col} ${styles._col_second} col-12 col-sm-12 col-md-3 col-lg-3`}>
                            <div className={`${styles._row_second} ${styles.row_mobile} row`}>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/blog/lenovo-warranty-check")}
                                    onClick={()=>{footerLinkClick('/blog/lenovo-warranty-check')}}>Lenovo Warranty Check</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/blog/samsung-warranty-check")}
                                    onClick={()=>{footerLinkClick('/blog/samsung-warranty-check')}}>Samsung Warranty Check </Link></div>
                            </div>

                        </div>
                        <div className={`${styles._footer_col}  col-12 col-sm-12 col-md-3 col-lg-2`}>
                            <h4>Lending Service Provider</h4>
                            <div className={`${styles._row_second} row`}>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/customer-consent")}
                                    onClick={()=>{footerLinkClick('/customer-consent')}}>Customer Consent</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/lending-service-partners")}
                                    onClick={()=>{footerLinkClick('/lending-service-partners')}}>LSP Partners</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/grievance-redressal")}
                                    onClick={()=>{footerLinkClick('/grievance-redressal')}}>Grievance Redressal</Link></div>

                            </div>
                        </div>


                    </div>
                </Hidden>
                <Hidden only={['xs']}>
                    <div className="row">
                    <div className={`${styles._footer_col} col-12 col-sm-12 col-md-12 col-lg-3`}>
                            <div className={`${styles._inner_row} ${styles._row_first} row`}>
                                <div className={`col-12 col-sm-12 col-md-6 col-lg-12`}>{LeftColJSX}</div>
                                <div className={`${styles.referral} col-12 col-sm-12 col-md-6 col-lg-12`}>
                                    {ReferralJSX}
                                </div>
                            </div>
                        </div>
                            <div className={`${styles._footer_col} col-12 col-sm-12 col-md-3 col-lg-2`}>
                                <h4>Company</h4>
                                <div className={`${styles._inner_row} ${styles._row_first} row`}>
                                    <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><Link href="/about-us" onClick={()=>{footerLinkClick('/about-us')}}>About Us</Link></div>
                                    <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><a
                                        href={getUrl("https://onsitego.com/blog/")}
                                        onClick={()=>{footerLinkClick('https://onsitego.com/blog/')}}>Blog</a></div>
                                    <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><a href="/careers" onClick={()=>{footerLinkClick('/careers')}}>Careers</a></div>
                                    <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><Link href="/media" onClick={()=>{footerLinkClick('/media')}}>In The Media</Link></div>
                                    <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><Link href="/whitepapers" onClick={()=>{footerLinkClick('/whitepapers')}}>Whitepapers</Link></div>
                                    <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><Link href="/write-to-us" onClick={()=>{footerLinkClick('/write-to-us')}}>Contact Us</Link></div>
                                    <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><Link
                                        href="/website/sitemap"
                                        onClick={()=>{footerLinkClick('/website/sitemap')}}>Sitemap</Link></div>
                                </div>

                        </div>
                        <div className={`${styles._footer_col} col-12 col-sm-12 col-md-3 col-lg-2`}>
                            <h4>Products</h4>
                            <div className={`${styles._inner_row} ${styles._row_second} row`}>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/mobile-phones-extended-warranty-plans")}
                                    onClick={()=>{footerLinkClick('/mobile-phones-extended-warranty-plans'); setLocalStorage()}}>Mobile Phones</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/?question_popup=laptops")}
                                    onClick={()=>{footerLinkClick('/?question_popup=laptops'); setLocalStorage()}}>Laptops</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/tablets-extended-warranty-plans")}
                                    onClick={()=>{footerLinkClick('/tablets-extended-warranty-plans'); setLocalStorage()}}>Tablets</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/protection-plans/digital-cameras")}
                                    onClick={()=>{footerLinkClick('/protection-plans/digital-cameras'); setLocalStorage()}}>Digital Cameras</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/printers-scanners-extended-warranty-plans")}
                                    onClick={()=>{footerLinkClick('/printers-scanners-extended-warranty-plans'); setLocalStorage()}}>Printers & Scanners</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/ro-water-purifier-service-repair")}
                                    onClick={()=>{footerLinkClick('/ro-water-purifier-service-repair'); setLocalStorage()}}>Water Purifiers </Link></div>
                            </div>
                        </div>

                        <div className={`${styles._footer_col} col-12 col-sm-12 col-md-3 col-lg-2`}>
                            <div className={`${styles._inner_row} ${styles._row_second} ${styles._row_extra} row`}>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/ac-service-repair")}
                                    onClick={()=>{footerLinkClick('/ac-service-repair'); setLocalStorage()}}>Air Conditioners</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/?question_popup=washing-machines")}
                                    onClick={()=>{footerLinkClick('/?question_popup=washing-machines'); setLocalStorage()}}>Washing Machine</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/?question_popup=refrigerators")}
                                    onClick={()=>{footerLinkClick('/?question_popup=refrigerators'); setLocalStorage()}}>Refrigerators</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/?question_popup=microwave")}
                                    onClick={()=>{footerLinkClick('/?question_popup=microwave'); setLocalStorage()}}>Microwaves</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/televisions-extended-warranty-plans")}
                                    onClick={()=>{footerLinkClick('/televisions-extended-warranty-plans'); setLocalStorage()}}>Televisions</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/fitness-trackers-extended-warranty-plans")}
                                    onClick={()=>{footerLinkClick('/fitness-trackers-extended-warranty-plans'); setLocalStorage()}}>Fitness Tracker</Link></div>
                            </div>

                        </div>
                        <div className={`${styles._footer_col}  col-12 col-sm-12 col-md-3 col-lg-2`}>
                            <div className={`${styles._inner_row} ${styles._row_second} ${styles._row_extra} row`}>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/desktops-extended-warranty-plans")}
                                    onClick={()=>{footerLinkClick('/desktops-extended-warranty-plans'); setLocalStorage()}}>Desktop</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/smart-watches-extended-warranty-plans")}
                                    onClick={()=>{footerLinkClick('/smart-watches-extended-warranty-plans'); setLocalStorage()}}>Smartwatch</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/home-repair-maintenance-plans")}
                                    onClick={()=>{footerLinkClick('/home-repair-maintenance-plans'); setLocalStorage()}}>HomeCare</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/chimney-hob-repair-service")}
                                    onClick={()=>{footerLinkClick('/chimney-hob-repair-service')}}>Chimney & Hob</Link></div>
                            </div>
                        </div>


                        </div>
                        <div className={`row ${styles.desktop_view}`}>
                            <div className={`${styles._footer_col} col-12 col-sm-12 col-md-12 col-lg-3`}>


                        </div>
                        <div className={`${styles._footer_col} ${styles._second_row_col} col-12 col-sm-12 col-md-3 col-lg-2`}>
                            <h4>Policies</h4>
                            <div className={`${styles._inner_row} row`}>
                                <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><Link href="/terms-of-use" onClick={()=>{footerLinkClick('/terms-of-use')}}>Terms Of Use</Link></div>
                                <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><Link href="/privacy-policy" onClick={()=>{footerLinkClick('/privacy-policy')}}>Privacy Policy</Link></div>
                                <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href="/terms-of-service/website"
                                    onClick={()=>{footerLinkClick('/terms-of-service/website')}}>Cancellations</Link></div>
                                <div className={`${styles._footer_link} col-4 col-sm-3 col-md-12 col-lg-12`}><Link href="/annual-returns" onClick={()=>{footerLinkClick('/annual-returns')}}>Annual Returns</Link></div>
                            </div>

                        </div>
                        <div className={`${styles._footer_col} ${styles._second_row_col} col-12 col-sm-12 col-md-4 col-lg-2`}>
                            <h4>Warranty Check</h4>
                            <div className={`${styles._row_second} row`}>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/blog/apple-warranty-check/")}
                                    onClick={()=>{footerLinkClick('/blog/apple-warranty-check')}}>Apple Warranty Check</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/blog/iphone-warranty-check/")}
                                    onClick={()=>{footerLinkClick('/blog/iphone-warranty-check')}}>Iphone Warranty Check</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/blog/dell-warranty-check/")}
                                    onClick={()=>{footerLinkClick('/blog/dell-warranty-check')}}>Dell Warranty Check</Link></div>

                            </div>
                        </div>
                        <div className={`${styles._footer_col} ${styles._second_row_col} col-12 col-sm-12 col-md-4 col-lg-2`}>
                            <div className={`${styles._row_second} ${styles._row_extra} row`} >
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/blog/sony-warranty-check/")}
                                    onClick={()=>{footerLinkClick('/blog/sony-warranty-check')}}>Sony Warranty Check</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/blog/lenovo-warranty-check/")}
                                    onClick={()=>{footerLinkClick('/blog/lenovo-warranty-check')}}>Lenovo Warranty Check</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/blog/samsung-warranty-check/")}
                                    onClick={()=>{footerLinkClick('/blog/samsung-warranty-check')}}>Samsung Warranty Check </Link></div>
                            </div>

                        </div>
                        <div className={`${styles._footer_col} ${styles._second_row_col} col-12 col-sm-12 col-md-4 col-lg-2`}>
                            <h4 className={styles._double_line_title}>Lending Service Provider</h4>
                            <div className={`${styles._row_second} row`}>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/customer-consent")}
                                    onClick={()=>{footerLinkClick('/customer-consent')}}>Customer Consent</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/grievance-redressal")}
                                    onClick={()=>{footerLinkClick('/grievance-redressal')}}>Grievance Redressal</Link></div>
                                <div className={`${styles._footer_link} col-6 col-sm-3 col-md-12 col-lg-12`}><Link
                                    href={getUrl("/lending-service-partners")}
                                    onClick={()=>{footerLinkClick('/lending-service-partners')}}>LSP Partners</Link></div>

                            </div>
                        </div>
                        <div className={`${styles._footer_col} col-12 col-sm-12 col-md-3 col-lg-3`}>
                            <div className={`${styles._inner_row} ${styles._row_second} row`}>
                            </div>
                        </div>
                    </div>
                </Hidden>

            </div>}
            <div className={`${styles._footer_bottom} container`}>
                <div className="row">
                    <div className={`${styles._footer_left} col-12 col-sm-12 col-md-6 col-lg-6`}>
                        <span>Secure Payment</span>
                        <Image src="/static/images/footer-payment.webp" alt="Payment Methods" unoptimized="true" width="325px" height="25px"/>
                    </div>
                    <div className={`${styles._footer_right} col-12 col-sm-12 col-md-6 col-lg-6`}>
                        <span>2010-2024 © Onsitego. All Rights Reserved</span>
                    </div>
                </div>
            </div>
        </footer>
    );
}


const LeftColJSX = (
    <>
        <span className={styles._branding}>
            <Link href="/">
                <Image src={"/static/images/onsite-logo-inverse.webp"} alt="Onsitego" unoptimized="true" width="120" height="32" />
            </Link>
        </span>
        <span className={styles._desc}>Expert Care For Your Devices</span>
        <ul className={styles._social_list}>
            <li className={styles._social_list__item} onClick={(e)=>{socialMediaIconClick({'social_media_link':'https://www.facebook.com/onsitego/','name':'facebook'})}}>
                <Link target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/onsitego/">
                    <Image src={"/static/images/social/fb.svg"} alt="Facebook" unoptimized="true" width="36" height="36" />
                </Link>
            </li>
            <li className={styles._social_list__item} onClick={(e)=>{socialMediaIconClick({'social_media_link':'https://twitter.com/onsitego','name':'twitter'})}}>
                <Link target="_blank" rel="noopener noreferrer" href="https://twitter.com/onsitego">
                    <Image src={"/static/images/social/x.png"} alt="Twitter" unoptimized="true" width="36" height="36" />
                </Link>
            </li>
            <li className={styles._social_list__item} onClick={(e)=>{socialMediaIconClick({'social_media_link':'https://www.instagram.com/onsitego','name':'instagram'})}}>
                <Link target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/onsitego">
                    <Image src={"/static/images/social/in.svg"} alt="Instagram" unoptimized="true" width="36" height="36" />
                </Link>
            </li>
            <li className={styles._social_list__item} onClick={(e)=>{socialMediaIconClick({'social_media_link':'https://www.youtube.com/channel/UC454stNyb50FTZ6uCEtLlYw','name':'youtube'})}}>
                <Link target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UC454stNyb50FTZ6uCEtLlYw">
                    <Image src={"/static/images/social/yu.svg"} alt="Youtube" unoptimized="true" width="36" height="36" />
                </Link>
            </li>
        </ul>
    </>
)

const ReferralJSX = (
    <div className={styles.referral_div}>
        <p>Refer to win a <br /> fitness band & many <br /> rewards!</p>
        <div className={styles.referral_img}>
            <Image src={`/static/images/refer-and-earn/${isMobile ? "mobile_referral.webp" : "desktop_referral.webp"}`} width={isMobile ? "170" : "118"} height={isMobile?"112":"108"} alt="referrl footer"></Image>
        </div>
        <Button text="Refer Now" onClick={() => {}} href='/refer-and-earn?referral_source=website_footer' target='#' className={styles.referral_button}/>
    </div>
                                
) 

export default Footer;
